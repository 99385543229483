@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');

html,
body {
  min-height: 100vh;
  display: flex;
  flex: 1;
}

#root {
  display: flex;
  flex: 1;
}

.grid-column-scroll {
  height: 500px;
  /* overflow-y: scroll; */
}

@media only screen and (max-width: 480px){
  .Toastify__toast-theme--light{
    width:calc(100vw);
  }
}

.Toastify__toast--info {
  color: #2C3446 !important;
  border: 1px solid #ACC1F0 !important;
  background: #F2F7FE !important;
  padding: '5px 10px';
  font-size: '14px';
  line-height: '24px';
  font-weight: 400
}

.Toastify__toast--success {
  color: #2C3446 !important;
  border: 1px solid #A6CFAB !important;
  background: #ECFBEE !important;
  padding: '5px 10px';
  font-size: '14px';
  line-height: '24px';
  font-weight: 400
}

.Toastify__toast--error {
  color: #2C3446 !important;
  border: 1px solid #FFC8C9 !important;
  background: #FDF1F1 !important;
  padding: '5px 10px';
  font-size: '14px';
  line-height: '24px';
  font-weight: 400
}

.Toastify__toast--warning {
  color: #2C3446 !important;
  border: 1px solid #FFEA95 !important;
  background: #FEF8E3 !important;
  padding: '5px 10px';
  font-size: '14px';
  line-height: '24px';
  font-weight: 400
}

.cc-list__header{
  display: none!important;
}
.cc-list__item{
  padding-bottom: 10px;
  border-bottom:1px solid #EAECF0;
}
.cc-list__item:not(:has(:first-child)) {
  display: none;
}
.cc-list__content {
  gap: 15px;
  margin-top: 25px;
}
.cc-message-options {
  display: none!important;
}
.cc-message-bubble__avatar{
  display: none !important;
}
.cc-message-bubble__content{
  background:#fff!important;
}
.cc-message-bubble__content > div{
  background:#fff!important;
}
.cc-message-composer__secondary-content{
  width:225px!important;
  height: 160px!important;
  border:0px!important
}
.cc-message-bubble__statusInfo{
  margin-top: 0px!important;
  font-size: 18px!important;
  color: #333!important;
}
.messageinput__sticky {
  background: RGBA(20, 20, 20, 0.04);
  border-radius: 0 0 8px 8px;
}
.messageinput-input{
  border-radius: 8px 8px 0 0!important;
}
.cc-message-composer__primary-view button{
  border: none !important;
  background: none !important;
}
.cc-message-composer__auxiliary-view {
  border-right: 1px solid #d2d0d0;
}
.react-multiple-carousel__arrow--left {
  left:20px!important;
  border-radius:0 8px 8px 0!important;
}

.react-multiple-carousel__arrow--right {
  right:18px!important;
  border-radius:8px 0 0 8px!important;
}
.react-multiple-carousel__arrow {
  background:#FFF!important;
  box-shadow:0px 0px 4px 0px #00000040
}
.react-multiple-carousel__arrow::before {
  color: #295FFE!important;
}
li::marker {
  color: #B2CCFF!important;
  font-size: 26px;
}

